import * as React from "react";
import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as JsSearch from "js-search";
import SearchResults from "./SearchResults";

const SearchFilter = (props: any) => {
  const [allNonProfitPages, setAllNonProfitPages] = useState([]);
  const [finalSearchResults, setFinalSearchResults] = useState([]);
  const [query, setQuery] = useState(props.state?.search || "");
  const [issue, setIssue] = useState(props.state?.issue || "");
  const [campaign, setCampaign] = useState(props.state?.campaign || "");
  const [age, setAge] = useState(props.state?.age || "");

  const data = useStaticQuery(graphql`
    {
      nonprofit_pages: allWpPost(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "Nonprofit Page" } } }
          }
        }
      ) {
        nodes {
          id
          title
          content
          link
          categories {
            nodes {
              name
            }
          }
          nonprofitPageMetadata {
            ageGroup
            serviceProjectType
          }
          nonprofitPageSubPages {
            subPages {
              content
              title
            }
          }
        }
      }

      issue_pages: allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Issue Page" } } } }
        }
      ) {
        nodes {
          issueLinkBoxes {
            relatedNonprofitPages {
              nonprofitPage {
                ... on WpPost {
                  title
                  content
                  link
                  id
                }
              }
            }
          }
          title
        }
      }
    }
  `);

  useEffect(() => {
    const nonProfitPages = data.nonprofit_pages;
    if (props.state) {
      setQuery(props.state.search);
      setIssue(props.state.issue);
      setCampaign(props.state.selectedCampaigns);
      setAge(props.state.selectedAges);
    }
    setAllNonProfitPages(nonProfitPages.nodes);
  }, [props.state]);

  useEffect(() => {
    const search = new JsSearch.Search("id");
    /**
     *  defines a indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    search.indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    search.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    search.searchIndex = new JsSearch.TfIdfSearchIndex("id");
    search.addIndex("content"); // sets the index attribute for the data
    search.addIndex("title"); // sets the index attribute for the data
    search.addIndex(["nonprofitPageSubPages", "subPages", "title"]); // sets the index attribute for the data
    search.addIndex(["nonprofitPageSubPages", "subPages", "content"]); // sets the index attribute for the data
    // search.addIndex("subpage_content"); // sets the index attribute for the data
    // search.addIndex("subpage_titles"); // sets the index attribute for the data
    search.addDocuments(allNonProfitPages); // adds the data to be searched

    // Use the JsSearch library to get filtered list of documents based on the search query or just use all documents.
    var searchResults = query ? search.search(query) : allNonProfitPages;

    // find nonprofits associated with issue
    const nonprofits_of_issue_page =
      issue &&
      data.issue_pages.nodes.filter((page: any) => page.title === issue)[0];

    // get links to nonprofits
    // What we return here is dependent on how we wuse the nonProfitPages
    const nonprofit_links =
      nonprofits_of_issue_page.issueLinkBoxes?.relatedNonprofitPages.map(
        (page: any) => page.nonprofitPage
      );

    // filter to those nonprofits
    // if none, return empty results
    if (nonprofit_links) {
      const np_ids = nonprofit_links.map((np) => np.id);
      searchResults = searchResults.filter((result: any) => {
        return np_ids.includes(result.id);
      });
    }

    // if age and campaign
    // need to filter on those
    if (age?.length > 0) {
      searchResults = searchResults.filter((result: any) =>
        result.nonprofitPageMetadata?.ageGroup?.some((r: any) =>
          age.includes(r)
        )
      );
    }

    if (campaign?.length > 0) {
      searchResults = searchResults.filter((result: any) =>
        result.nonprofitPageMetadata?.serviceProjectType.some((r: any) =>
          campaign.includes(r)
        )
      );
    }

    setFinalSearchResults(searchResults);
  }, [query, issue, campaign, age]);

  return (
    <SearchResults results={finalSearchResults} query={query}></SearchResults>
  );
};
export default SearchFilter;
