import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Search from "../search";
import SearchFilter from "../../components/Helpers/SearchFilter";

const results = (props: any) => {
  return (
    <Layout title={"Find a Service Project"}>
      <SEO title="Find a Service Project" />
      <SearchFilter state={props.location.state}></SearchFilter>
    </Layout>
  );
};

export default results;
